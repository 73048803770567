.partners {
  padding: 0 max(min(6vw, 140px), 35px);

  .partnersTitle {
    font-size: 22px;
    font-weight: 500;
    color: #1F1F1F;
    text-align: center;
  }

  .partnersLogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 60px 0;

    .partnersLogo {
      padding: 0 60px;
    }
  }
}

@media (max-width: 1023px) {
  .partners {
    .partnersLogos {
      flex-direction: column;
      padding: 40px 0;

      .partnersLogo {
        padding: 15px 0;
      }
    }
  }
}