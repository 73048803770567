.testimonials {
  padding: 100px 12vw;

  .testimonialsContainer {
    position: relative;
    border-radius: 50px;
    overflow: hidden;
  }
}

.testimonialItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .testimonialItemLeft {
    width: 100%;
    padding: 40px 60px;
  }

  .testimonialItemTitle {
    font-size: 14px;
    font-weight: 600;
    color: #D11A6D;
    text-transform: uppercase;
    margin-bottom: 22px;
  }

  .testimonialItemQuote {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 36px;
    line-height: 34px;

    &.testimonialItemQuoteSmall {
      font-size: 20px;
      line-height: 30px;
    }

    &.testimonialItemQuoteXSmall {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .testimonialItemName {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  .testimonialItemDescription {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .testimonialItemDots {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: -5px;

    .testimonialItemDotContainer {
      padding: 5px;
      cursor: pointer;

      .testimonialItemDot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #A8A8A8;
        transition: all 0.3s;
      }

      .testimonialItemDotActive {
        background: #000000;
      }
    }
  }
}

.testimonialsPrevItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s;
}

.testimonialItemChanging {
  opacity: 0 !important;
}

@media (max-width: 1335px) {
  .testimonialsContainer {
    border-radius: 40px !important;
  }

  .testimonialItem {
    .testimonialItemQuote {
      font-size: 20px !important;
      margin-bottom: 22px !important;
      line-height: 30px;
    }

    .testimonialItemName {
      font-size: 18px !important;
      font-weight: 600 !important;
    }
  }
}

@media (max-width: 1023px) {
  .testimonials {
    padding: 100px 0;

    .testimonialsContainer {
      border-radius: 30px !important;
    }

    .testimonialItem {
      flex-direction: column !important;

      .testimonialItemLeft {
        width: 100% !important;
        padding: 40px !important;
      }

      .testimonialItemRight {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .testimonials {
    .testimonialItem {
      .testimonialItemLeft {
        padding: 30px !important;
      }
    }
  }
}