.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F1F1F1;
  padding: 100px max(min(6vw, 140px), 35px) 100px max(min(6vw, 140px), 35px);

  .heroLeft {
    width: 50%;
    padding-right: 30px;
  }

  .heroRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    img {
      border-top-left-radius: 45px;
      border-bottom-right-radius: 45px;
    }
  }

  .heroTitle {
    color: #1F1F1F;
    font-size: max(34px, 2.75vw);
    font-weight: 600;
    margin-bottom: 30px;
    line-height: calc(max(34px, 2.75vw) + 12px);
  }

  .heroDescription {
    color: #1F1F1F;
    font-size: max(24px, 1.75vw);
    font-weight: 500;
    margin-bottom: 30px;
  }

  .heroButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 40px;

    .heroButton {
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .hero {
    flex-wrap: wrap !important;

    .heroButtons {
      width: 100% !important;
      margin-top: 20px !important;
      margin-bottom: 0 !important;
      justify-content: center;
    }
  }
}

@media (max-width: 1023px) {
  .hero {
    flex-direction: column;
    padding: 40px max(min(6vw, 140px), 35px);

    .heroLeft {
      padding-right: 0 !important;
      width: 100%;
    }
    
    .heroTitle {
      text-align: center;
    }
    
    .heroDescription {
      text-align: center;
    }

    .heroButtons {
      flex-direction: column;
      justify-content: center;

      .heroButton {
        width: 300px !important;
        max-width: 100% !important;
      }
    }

    .heroRight {
      width: 100%;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .hero {
    .heroButtons {
      margin-bottom: 40px;
    }
  }
}