.contactItem {
  background: #ffffff;
  border-radius: 15px;
  padding: 35px 35px 25px 35px;
  min-height: 100%;

  .contactItemIcon {
    margin-bottom: 20px;

    svg {
      font-size: 36px;
      color: #D11A6D;
    }
  }

  .contactItemTitle {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .contactItemDescription {
    font-size: 13px;
    font-weight: 600;
    color: #D11A6D;
    text-transform: uppercase;
    margin-bottom: 22px;
  }

  .contactItemButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .button {
    max-width: none !important;
    width: 100% !important;
  }
}