.itemSection {
  padding: 100px 0 120px 0;

  .itemSectionContainer {

  }

  .itemSectionTitle {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 22px;
  }

  .itemSectionDescription {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 37px;
    line-height: 44px;

    a {
      color: #D11A6D;
    }
  }

  .itemSectionItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    &.itemSectionItemsLeft {
      justify-content: flex-start;
    }

    .itemSectionItem {
      width: 25%;
    }
  }

  &.size-3 {
    .itemSectionItems {
      .itemSectionItem {
        width: 33.33%;
      }
    }
  }

  &.size-2 {
    .itemSectionItems {
      .itemSectionItem {
        width: 50%;
      }
    }
  }

  &.size-1 {
    .itemSectionItems {
      .itemSectionItem {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1535px) {
  .itemSection {
    .itemSectionItems {
      .itemSectionItem {
        width: 33.33% !important;
      }
    }
  
    &.size-2 {
      .itemSectionItems {
        .itemSectionItem {
          width: 50% !important;
        }
      }
    }
  
    &.size-1 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 1365px) {
  .itemSection {
    .itemSectionItems {
      justify-content: center !important;

      &.itemSectionItemsLeft {
        justify-content: flex-start !important;
      }

      .itemSectionItem {
        width: 50% !important;
      }
    }
  
    &.size-2 {
      .itemSectionItems {
        .itemSectionItem {
          width: 50% !important;
        }
      }
    }
  
    &.size-1 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .itemSection {
    padding: 100px 0 70px 0;
  }
}

@media (max-width: 767px) {
  .itemSection {
    padding: 100px 0 70px 0;

    .itemSectionItems {
      flex-direction: column !important;

      .itemSectionItem {
        width: 100% !important;
      }
    }
    
    &.size-4 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }
    
    &.size-3 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }
    
    &.size-2 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }

    &.size-1 {
      .itemSectionItems {
        .itemSectionItem {
          width: 100% !important;
        }
      }
    }
  }
}